@use './../bootstrap/variables' as *;

body,
footer {
    overflow-x: hidden;
    position: relative;
}

@media (min-width: 1450px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1400px;
    }
}

.row {
    > * {
        @media (min-width: 1900px) {
            padding-left: 1.07rem;
            padding-right: 1.07rem;
        }
    }

    .same-height {
        padding-bottom: 1.5rem;

        @media (min-width: 1900px) {
            padding-bottom: 2.14rem;
        }
    }
}

figure {
    margin: 0;
}

.mw-100-img img {
    max-width: 100%;
}

small {
    font-size: 11px;
}

p a {
    text-decoration: underline;
}

.button-more-with {
    width: 100%;

    @media (min-width: 768px) {
        width: 385px;
    }
}

.mb-n-0 * {
    margin-bottom: 0;
}

.lang {
    text-transform: uppercase;

    &.active {
        display: none;
    }
}

.font-400-inter,
.font-400-inter * {
    font-family: Inter-Regular, sans-serif;
    font-weight: unset;
}

strong,
.font-700-inter,
.font-700-inter * {
    font-family: Inter-Bold, sans-serif;
    font-weight: unset;
}

.w-100-img img {
    width: 100%;
}

.wm-100-img img {
    max-width: 100%;
}

.btn-xxl-width {
    min-width: 100%;

    @media (min-width: 567px) {
        min-width: 385px;
    }
}

.text-underline,
.text-underline * {
    text-decoration: underline;
}

table {
    font-size: 0.95rem;
    width: 100%;

    tr {
        border-bottom: 1px solid $info;
    }

    td {
        padding: 0.7rem 0;
    }
}

.response-img {
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.w-xxl-auto {
    @media (min-width: 1400px) {
        width: auto !important;
    }
}

.linear-gradient1 {
    background: linear-gradient(90deg, #00252e 41.75%, #006a82 87.83%);
}

.pt-1px {
    padding-top: 1px;
}

.small-text,
.small-text * {
    font-size: 11px;
}

.z-index-2,
.z-index-2 * {
    position: relative;
    z-index: 2;
}

.latest-articles-w {
    @media (min-width: 567px) {
        width: 385px;
    }
}

.card-settings {
    .col {
        padding-left: 0;
        padding-right: 0;
    }
}

.w-100-md-50 {
    width: 100%;

    @media (min-width: 768px) {
        width: 50%;
    }
}

.w-100-lg-50 {
    width: 100%;

    @media (min-width: 992px) {
        width: 50%;
    }
}
