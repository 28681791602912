@use './../bootstrap/variables' as *;

// nav mobile all pages
@media (max-width: 1199px) {
    .page-navigation {
        padding-top: 20px;

        li {
            display: block;
            margin: 0;
            padding: 0;
        }

        a {
            color: $dark;
            display: inline-block;
            font-family: 'Inter-Regular', sans-serif;
            font-size: 1.05rem;
            margin-left: 3px;
            padding: 0.85rem 0;
            width: 100%;

            &[target]:not(.btn) {
                align-items: center;
                display: flex;
                padding-left: 10px;
            }

            &[target]:not(.btn)::before {
                content: url('../img/arrow-menu-link-mobile.svg');
                height: 24px;
                left: -10px;
                position: relative;
                width: 24px;
            }
        }

        .undermenu {
            background-color: transparent;
            border-radius: 0;
            box-shadow: none;
            opacity: 1;
            padding: 0;
            position: relative;
            transform: translate(0, 0);
            visibility: visible;

            li {
                margin-left: 1rem;

                a {
                    box-shadow: none;
                    line-height: 1;
                    margin-left: 0 1rem;
                    width: auto;
                }
            }
        }
    }
}
