// header-container
.header-container {
    height: 80px;
    transition: all 0.15s ease-in-out;
    width: 100%;

    > .row {
        height: 80px;
        margin: 0;
        padding: 0;

        @media (min-width: 1200px) {
            height: auto;
        }
    }

    .col {
        padding: 0;
    }

    .header-mobile {
        width: 100%;

        @media (max-width: 1199px) {
            padding: 15px 0;
        }
    }

    .logo {
        margin-left: 15px;
    }

    #toggleMenu {
        cursor: pointer;
        height: 50px;
        width: 50px;
    }

    @media (min-width: 576px) {
        max-width: 100%;

        .header-mobile {
            margin: auto;
        }
    }

    @media (min-width: 1200px) {
        margin-left: auto;
        margin-right: auto;
        padding: 0;

        .header-mobile {
            margin-left: 0;
            max-width: 140px;
        }
    }

    @media (min-width: 1200px) {
        height: auto;
        max-width: 100%;
    }

    @media (min-width: 1450px) {
        max-width: 1400px;
    }
}
