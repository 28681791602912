
.btn-primary {
    @include button-variant( // bg
        $primary,
        // border-color
        $primary,
        // color
        $white,
        // hover:bg
        #44cad2,
        // hover:border-color
        #44cad2,
        // hover:color
        $white,
        // active:bg
        $dark,
        // active:border-color
        $dark,
        // active:color
        $white );
}

.btn-secondary {
    @include button-variant( // bg
        $secondary,
        // border-color
        $secondary,
        // color
        $dark,
        // hover:bg
        #d2fff4,
        // hover:border-color
        #d2fff4,
        // hover:color
        $dark,
        // active:bg
        #009C80,
        // active:border-color
        #009C80,
        // active:color
        $dark);
}

.btn-light,
.btn-info {
    @include button-variant( // bg
        $white,
        // border-color
        $primary,
        // color
        $primary,
        // hover:bg
        #e5feff,
        // hover:border-color
        $primary,
        // hover:color
        $primary,
        // active:bg
        $info,
        // active:border-color
        $primary,
        // active:color
        $primary);
}

.btn-info {
    border-width: 2px;
}
