@use './../bootstrap/variables' as *;

.tabs-deployments > div {
    @media (min-width: 992px) {
        align-items: flex-start;
        display: flex;
    }

    .nav.nav-pills {
        border: 0;
        display: block;
        margin-bottom: 50px;
        transition: all 0.6s ease;

        @media (min-width: 992px) {
            max-width: 316px;
            position: sticky;
            width: 30%;
        }

        .nav-link {
            align-items: center;
            border-bottom: 1px solid $border !important;
            border-radius: 0;
            border-top: 0;
            color: $dark;
            display: flex;
            font-size: 16px;
            line-height: 1;
            padding: 0.7rem 10px;
            text-align: left;
            width: 100%;

            &:last-of-type > * {
                border: 0;
            }
        }

        li {
            width: 100%;

            .nav-link {
                &::before {
                    padding-right: 20px;
                }
            }

            &:first-of-type .nav-link {
                &::before {
                    content: url('./../img/ixp.svg');
                }
            }

            &:nth-of-type(2) .nav-link {
                &::before {
                    content: url('./../img/data-center.svg');
                }
            }

            &:nth-of-type(3) .nav-link {
                &::before {
                    content: url('./../img/cdn.svg');
                }
            }

            &:nth-of-type(4) .nav-link {
                &::before {
                    content: url('./../img/isp.svg');
                }
            }

            &:nth-of-type(5) .nav-link {
                &::before {
                    content: url('./../img/product-integration.svg');
                }
            }
        }

        .nav-link.active {
            background-color: #ddf0f6; //DDF0F6
            border: 0;
            border-bottom: 1px solid $border;
            font-family: Inter-Bold, sans-serif;
            position: relative;

            &::after {
                content: url('./../img/arrow-pills.svg');
                position: absolute;
                right: 20px;
                transform: rotate(90deg);

                @media (min-width: 992px) {
                    transform: rotate(0deg);
                }
            }
        }
    }

    .tab-content {
        @media (min-width: 992px) {
            margin-left: 50px;
            width: 70%;
        }

        article {
            border-bottom: 1px solid $info;
            padding: 18px 0;

            &:last-of-type {
                border-bottom: 0;
            }
        }

        .about-cards.active {
            align-items: stretch;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            > * {
                margin-bottom: 20px;
                width: 100%;

                @media (min-width: 992px) {
                    padding: 0 1rem;
                    width: 33%;
                }
            }
        }

        .bg-body {
            background-color: inherit !important;
        }
    }
}
